<template>
    <div>
        <LiefengContent>
            <template v-slot:title>模板引用详情</template>
            <template v-slot:toolsbarRight></template>
            <template v-slot:contentArea>
                <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"

export default {
    components: { LiefengContent, LiefengTable },
    data() {
        return {
            talbeColumns: [
                {
                    title: "模板名称",
                    minWidth: 200,
                    align: "center",
                    key: "templateName",
                    render: (h, params) => {
                        return h("div", {}, this.$route.query.templateName)
                    },
                },
                {
                    title: "引用机构",
                    minWidth: 200,
                    align: "center",
                    key: "orgName",
                },
                {
                    title: "操作人",
                    minWidth: 200,
                    align: "center",
                    key: "staffName",
                },
                {
                    title: "操作时间",
                    key: "time",
                    width: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
            ],
            tableData: [],
            loading: false,
            page: 1,
            pageSize: 20,
            total: 0,
        }
    },
    created() {},
    mounted() {
        this.getList()
    },
    methods: {
        // 分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 获取表格列表
        getList() {
            this.loading = true
            this.$get("/info/api/pc/information/template/selectLogView", {
                templateId: this.$route.query.informationId,
                page: this.page,
                pageSize: this.pageSize,
            }).then(res => {
                this.loading = false
                if (res.code == 200) {
                    this.tableData = res.dataList
                    this.total = res.maxCount
                } else {
                    this.$Message.error({
                        content: "数据获取失败",
                        background: true,
                    })
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
</style>